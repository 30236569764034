@import '../../Containers/common';

.phoneModal {
  @extend %flexRowContainer;
  align-items: center;
  column-gap: 20px;

  .phoneIcon {
    img {
      width: 30px;
      height: 30px;
    }
  }
}