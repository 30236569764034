@import "../common";

.header {
  margin-top: 0;

  .stepper {
    margin-top: 48px;
    width: 488px;
  }

  .breadcrumbs {
    margin-top: 8px;
  }
}

.err {
  color: #f53e3e;
  font-size: 12px;
  margin-right: 30px;
}

.footer {
  *:first-child {
    margin-left: auto;
  }
}