.stepper {
  margin-top: 48px;
  width: 488px;
}

.footer {
  .message {
    margin-left: auto;
  }
}
