@import '../../../common';

.conditionHeader {
  display: inline-flex;
  align-items: center;

  img {
    margin-left: 20px;
  }
}

.attributes {
  display: grid;
  column-gap: 80px;
  row-gap: 18px;
  margin-top: 18px;
  grid-template-rows: 1fr max-content;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  %multiInputGrid {
    display: grid;
    grid-template-rows: 1fr;
    column-gap: 15px;
    align-items: center;
  }

  .withValue {
    @extend %multiInputGrid;
    grid-template-columns: 120px 173px 15px 173px;
  }

  .to {
    margin-top: 15px;
  }

  .between {
    @extend %multiInputGrid;
    grid-template-columns: max-content 15px max-content;
  }
}

.entryConditionsPreview {
  display: grid;

  .attributesPreview {
    @extend %gridContainer;
    margin-top: 18px;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 1fr;
  }
}

.excludeDesc {
  margin-bottom: 18px;
}