@import '../common';

.heading {
  @extend %flexRowContainer;

  button {
    margin-left: auto;
  }
}

.statistics {
  @extend %container;
  @extend %flexRowContainer;
  column-gap: 24px;
  margin-top: 40px;
}

.dates {
  @extend %gridContainer;
  margin-top: 18px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}