@import '../../../common';

.lbDuration {
  @extend %container;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 5fr 3fr 3fr 1fr 5fr 3fr 3fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.lbDurationPreview {
  margin-top: 18px;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 3fr 3fr 2fr 3fr 3fr;
  grid-template-rows: 1fr;
  align-items: center;
}
