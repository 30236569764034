@font-face {
    font-family: 'Gotham Rounded Bold';
    src: local('Gotham Rounded Bold'), url(../fonts/GothamRounded-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Gotham Rounded Book';
    src: local('Gotham Rounded Book'), url(../fonts/GothamRounded-Book.otf) format('opentype');
}

@font-face {
    font-family: 'Gotham Rounded Medium';
    src: local('Gotham Rounded Medium'), url(../fonts/GothamRounded-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Gotham Rounded Light';
    src: local('Gotham Rounded Light'), url(../fonts/GothamRounded-Light.otf) format('opentype');
}