@import '../../../../common';

.namingDetails {
  @extend %gridContainer;
  margin-top: 18px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);

  .internalName {
    grid-area: Input-1;
  }

  .externalName {
    grid-area: Input-2;
  }

  .description {
    grid-area: Input-3;
  }
}

.label {
  margin-bottom: 20px;
}

.bannerUpload {
}
