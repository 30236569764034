@import '../../common';

.propertyDetails {
  @extend %gridContainer;
  margin-top: 36px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    'Input-1 Input-1 Input-2 Input-2 Input-7 Input-7'
    'Input-4 Input-4 Input-5 Input-5 Input-6 Input-6';

  .internalName {
    grid-area: Input-1;
  }

  .externalName {
    grid-area: Input-2;
  }

  .description {
    grid-area: Input-3;
  }

  .visibleTime {
    grid-area: Input-4;
  }

  .startTime {
    grid-area: Input-5;
  }

  .endTime {
    grid-area: Input-6;
  }
}

.leaderboardCalendar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;

  button {
    position: relative;
    right: -150px;
  }
}

.calendarContainer {
  margin-right: 10px;
}

.propertyContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.summaryAction {
  right: -150px;
  position: relative;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
