@import "../../../common";

.leaderboardDetails {
  @extend %container;
  @extend %gridContainer;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr;
  margin-top: 24px;
  margin-bottom: -6px;
}

.leaderboardDetailsPreview {
  @extend %gridContainer;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  margin-top: 18px;

  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

.checkboxes {
  margin-top: 24px;
  @extend %gridContainer;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
}

.bannerUpload {
  margin-top: 18px;

  :global .filepond--item {
    width: calc(50% - .5em);
  }
}

.bannerUploadPreview {
  margin-top: 26px;

  :global .filepond--item {
    width: calc(50% - .5em);
  }
}

.nonCashBanners {
  margin-bottom: 24px;
}

.referralDetails {
  @extend %container;

  .degree {
    @extend %gridContainer;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .degreeRow {
    margin-top: 26px;
    @extend %gridContainer;
    column-gap: 25px;
    grid-template-columns: 60px 1fr;
    grid-template-rows: 1fr;
    align-items: end;

    .degreeName {
      margin-bottom: 7px;
    }
  }
}

.entryFeeDetails {
  @extend %container;

  .entryFeeInputs {
    @extend %container;
    @extend %gridContainer;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
          "Input-1 Input-2"
          "Input-3 Input-4"
          "Input-5 .";

    .entryFee {
      grid-area: Input-1;
    }

    .earlyBirdDiscount {
      grid-area: Input-2;
    }

    .slotLimit {
      grid-area: Input-3;
    }

    .discount {
      grid-area: Input-4;
    }

    .seedPlayers {
      grid-area: Input-5;
    }
  }
}

.lateRegistrationDetails {
  @extend %container;
  @extend %gridContainer;
  grid-template-columns: repeat(2, 1fr);
}

.lateRegistrationDetailsPreview {
  @extend %gridContainer;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 26px;
}