@import '../../Containers/common';

.csvReader {
  margin-top: 18px;
  max-width: 600px;
}

.csvMessage {
  margin-top: 36px;
  margin-bottom: 18px;
}

.csvStatus {
  @extend %gridContainer;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, max-content);

  > div {
    @extend %gridContainer;
    row-gap: 8px;
    grid-template-rows: max-content max-content;
    grid-template-columns: 1fr;
  }
}