@import '../../../common';

.prizeDetails {
  @extend %container;
  @extend %gridContainer;

  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);

  .totalBalance {
    @extend %gridContainer;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 8px;
  }
}

.prizeDetailsPreview {
  @extend %gridContainer;
  margin-top: 18px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
}

%prizeInputRow {
  @extend %gridContainer;
  column-gap: 25px;
  grid-template-rows: 1fr;
  align-items: end;
}

.prizeValues {
  @extend %container;
  @extend %gridContainer;
  margin-right: -130px;

  .delete {
    height: 30px;
    width: auto;
    cursor: pointer;
    margin-bottom: 4px;
  }
}

.prizeValuesPreview {
  @extend %gridContainer;
  margin-top: 44px;
}

.rankRow {
  @extend %prizeInputRow;
  grid-template-columns: 120px 120px 120px 175px repeat(2, 1fr) 30px;

  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.rankRowPreview {
  @extend %prizeInputRow;
  grid-template-columns: repeat(4, 1fr) 200px;
}

.rankRowWithStaggered {
  @extend %prizeInputRow;
  grid-template-columns: 120px 120px 120px 175px repeat(2, 1fr) 30px;

  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.rankRowWithNonCashPrizePreview {
  @extend %prizeInputRow;
  grid-template-columns: 75px 75px 125px 125px 1fr;
}

.goalRow {
  @extend %prizeInputRow;
  grid-template-columns: 50px repeat(3, 1fr) 30px;
}

.goalName {
  margin-bottom: 9px;
}

.footer {
  @extend %container;
  @extend %gridContainer;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  row-gap: 18px;
  margin-bottom: 0;

  button {
    justify-content: left;
  }
}
