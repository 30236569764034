@import '../../../common';

.tournamentDesign {
  @extend %container;
  @extend %gridContainer;

  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);

  &.range {
    grid-template-columns: repeat(3, 1fr);
  }
}

.bbHeading {
  @extend %gridContainer;
  grid-template-rows: 1fr;
  grid-template-columns: auto min-content;
  align-items: center;
}

.bbTable {
  @extend %container;
  margin-top: 25px;
  overflow: scroll;

  table {
    table-layout: fixed;
    border-spacing: 0 20px;

    th, td {
      padding: 5px 20px 5px 0;

      &:first-of-type {
        min-width: 120px;
      }
    }
  }

  .bbInputs {
    @extend %gridContainer;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    > div {
      width: 60px;

      .bbInput {
        height: 25px;
        font-size: 10px;
        padding-left: 8px;
      }

      .bbLabel {
        margin-left: -12px;
      }
    }
  }
}

.tournamentDesignPreview {
  @extend %gridContainer;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  margin-top: 18px;
}
