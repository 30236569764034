@import '../../common';

.header {
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;

  button {
    margin-left: auto;
    margin-right: -180px;
  }
}

.infoPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  margin-top: 36px;
  margin-bottom: 36px;
  margin-right: -180px;

  .legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;

    div {
      &:nth-of-type(even) {
        margin-right: 16px;
      }
    }
  }

  > div {
    &:last-of-type {
      margin-left: auto;

      input {
        margin-top: 0;
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;

  > button:first-of-type {
    min-width: 90px;
    justify-content: flex-start;
  }
}

.deleteModal {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  button {
    margin-left: auto;
  }
}

.footer {
  button:first-of-type {
    margin-left: auto;
  }
}
