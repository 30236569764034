.statisticCard {
  height: 100px;
  width: 260px;
  background-color: #F8F9FA;
  padding: 20px;
  box-sizing: border-box;

  .measure {
    margin-top: 9px;
  }

  .measurement {
    color: #27325F;
    line-height: 38px;
    font-size: 32px;
  }
}