%container {
  margin-top: 18px;
  margin-bottom: 26px;
}

%gridContainer {
  display: grid;
  gap: 26px 80px;
}

%flexRowContainer {
  display: flex;
  flex-direction: row;
}