@import '../../common';

.heading {
  @extend %flexRowContainer;

  button {
    margin-left: auto;
  }
}

.statistics {
  @extend %container;
  @extend %flexRowContainer;
  column-gap: 24px;
  margin-top: 40px;
}

.dates {
  @extend %gridContainer;
  margin-top: 18px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.namingDetails {
  @extend %gridContainer;
  margin-top: 18px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);

  .internalName {
    grid-area: Input-1;
  }

  .externalName {
    grid-area: Input-2;
  }

  .description {
    grid-area: Input-3;
  }
}

.label {
  margin-bottom: 20px;
}

.summaryImage {
  max-width: 300px;
  border-radius: 20px;
}
