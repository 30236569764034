@import "../../../common";

.namingDetails {
  @extend %gridContainer;
  margin-top: 18px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "Input-1 Input-1 Input-2 Input-2"
    "Input-3 Input-3 Input-3 Input-3";

  .internalName {
    grid-area: Input-1;
  }

  .externalName {
    grid-area: Input-2;
  }

  .description {
    grid-area: Input-3;
  }
}