@import "../../../common";

.depositDetails {
  @extend %container;
}

.depositDetailsPreview {
  @extend %gridContainer;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  margin-top: 18px;
}