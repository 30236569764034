@import '../../../common';

.gameDetails {
  @extend %container;
  @extend %gridContainer;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}

.gameDetailsPreview {
  @extend %gridContainer;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  margin-top: 18px;
}