@import '../../../common';

.terms {
  @extend %container;
  @extend %gridContainer;

  .term {
    @extend %gridContainer;
    grid-template-columns: auto 30px;
    grid-template-rows: 1fr;
    column-gap: 25px;
    align-items: center;

    &:first-of-type {
      grid-template-columns: 1fr;
    }

    .delete {
      height: 30px;
      width: auto;
      cursor: pointer;
    }
  }
}

.termsPreview {
  @extend %gridContainer;
  margin-top: 18px;
}