@import '../common';

.container {
  display: grid;
  grid-template-columns: 1fr;
}

.header {
  margin-top: 0;

  .heading {
    @extend %flexRowContainer;
    column-gap: 10px;
  }

  .sub {
    margin-top: 20px;
    margin-right: -180px;
    @extend %flexRowContainer;

    .actions {
      margin-left: auto;
      @extend %flexRowContainer;
      column-gap: 30px;
    }
  }
}

.activity {

  .heading {
    @extend %flexRowContainer;
    margin-right: -180px;
    margin-bottom: 26px;

    button {
      margin-left: auto;
    }
  }

  .filters {
    @extend %container;
    @extend %gridContainer;
    grid-template-rows: 1fr;
    grid-template-columns: 260px;
  }

  :global .infinite-scroll-component {
    overflow: unset !important;
  }

  .endMessage {
    margin-top: 36px;
    display: flex;
    justify-content: center;
    margin-right: -220px;
    margin-left: -40px;
  }

  .actions {
    @extend %flexRowContainer;
    column-gap: 15px;
  }
}
