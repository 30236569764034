@import '../../common';

.lbDuration {
  @extend %container;
  display: grid;
  column-gap: 20px;
  row-gap: 26px;
  grid-template-columns: repeat(auto-fit, minmax(484px, 12fr));
  grid-template-rows: 1fr;

  .inputs {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 5fr 3fr 3fr 12px;
    grid-template-rows: 1fr;
    max-width: 506px;
    align-items: center;
  }
}

.lbDurationPreview {
  margin-top: 18px;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 3fr 3fr 2fr 3fr 3fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.to {
  margin-top: 15px;
}

.message {
  margin-top: 36px;
}

.lbRecurrence {
  margin-top: 18px;
  @extend %gridContainer;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, max-content);
  row-gap: 44px;

  .when {
    width: 275px;
  }

  .repeatEvery {
    @extend %gridContainer;
    column-gap: 25px;
    grid-template-columns: 75px 175px;
    grid-template-rows: 1fr;
  }

  .repeatOn {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    .days {
      display: grid;
      grid-template-columns: repeat(7, 24px);
      column-gap: 17.5px;

      .day {
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        text-align: center;
        display: flex;
        height: 24px;
        width: 24px;
        background-color: #ebeffe;
        cursor: pointer;

        > div {
          color: #325ef1;
        }

        &.selected {
          background: #325ef1;

          > div {
            color: white;
          }
        }
      }
    }
  }

  .endsInputs {
    margin-top: 8px;
    display: grid;
    column-gap: 25px;
    grid-template-columns: 175px 75px;
    grid-template-rows: 1fr;
  }
}

.lbRecurrencePreview {
  margin-top: 18px;
}